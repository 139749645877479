import React, { useEffect, useState } from "react";
import { Html5Qrcode } from "html5-qrcode"

const Scaner = ({ onCode, active }) => {
    const [barCode, setBarCode] = useState(null);

    useEffect(() => setBarCode(new Html5Qrcode('reader')), []);

    const qrCodeSuccessCallback = async (decodedText, decodedResult) => {
        stop();
        onCode && onCode(decodedText)
    };

    const start = () => {
        if (!barCode) return;
        barCode.start({ facingMode: "environment" }, { fps: 10, qrbox: { width: 250, height: 95 } }, qrCodeSuccessCallback);
    }

    const stop = async () => {
        if (!barCode) return;
        await barCode.stop();
    }

    useEffect(() => active ? start() : stop(), [active]);

    return <div id="reader" width="600px" height="200px" className={'rounded overflow-hidden mb-3 ' + (!active ? ' d-none' : '')}></div>;
};

export default Scaner;