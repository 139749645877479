import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search, UpcScan } from 'react-bootstrap-icons';

import Scaner from "components/Scaner";
import { useFavourite } from "components/useFavourite";
import { useLastViewed } from "components/useLastViewed";
import { getPosts } from "app/Api";
import { ItemList } from "components/Items";

const Home = () => {
    const [posts, setPosts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [scanActivated, setScanActivated] = useState(false);
    const [favourite] = useFavourite();
    const [lastViewed] = useLastViewed();
    const navigate = useNavigate();

    useEffect(() => {
        const _getPosts = async () => {
            setPosts(await getPosts());
        };

        _getPosts();
    }, []);

    const onChange = e => e.target.value !== searchValue && setSearchValue(e.target.value);
    const onSearch = e => searchValue !== '' && posts.find(p => p === searchValue) && load(searchValue);
    const load = id => navigate('/posts/' + id);

    const searchResult = searchValue !== '' && posts.filter(p => p.startsWith(searchValue)).sort();

    return <>
        <Scaner onCode={code => load(code)} active={scanActivated}/>

        <div className="input-group input-group-lg mb-4">
            <input type="text" className="form-control" value={searchValue} onChange={onChange} placeholder="Search by number"/>
            <button className="btn btn-primary" type="button" disabled={searchValue === ''} onClick={onSearch}><Search/></button>
            <button className={'btn ' + (scanActivated ? ' btn-danger' : ' btn-outline-secondary')} type="button" onClick={() => setScanActivated(!scanActivated)}><UpcScan/></button>
        </div>

        {searchResult && <>
            {searchResult.length > 0
                ? <ItemList items={searchResult} limit={10} header="Search results"/>
                : `Cannot find anything started with "${searchValue}"`}
        </>}
        {!searchResult && lastViewed.length > 0 && <ItemList items={lastViewed} limit={5} header="Last viewed"/>}
        {!searchResult && favourite.length > 0 && <ItemList items={favourite.sort()}  header="Favourite"/>}
    </>;
};
export default Home;