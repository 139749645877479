async function apiFetch(URL, method, body) {
    try {
        const options = {};
        if (method) {
            options.method = method;
        }
        if (body) {
            options.body = body;
        }
        const resp = await fetch(process.env.REACT_APP_API_URL + URL, options);
        return await resp.json();
    } catch (error) {
        return null;
    }
}

const getPosts = async () => await apiFetch('/api/posts/') || []
const getPost = async id => await apiFetch('/api/posts/' + id)
const upload = async data => await apiFetch('/api/load/', 'POST', data)

export {
    apiFetch,
    getPosts,
    getPost,
    upload
};
export default apiFetch;