import { useState } from "react";

const getStorageValue = (key, defaultValue) => {
    if (!localStorage.getItem(key)) {
        return defaultValue
    }
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => getStorageValue(key, defaultValue));
    const setValue2 = newValue => {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
    }
    return [value, setValue2];
};