import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EmojiNeutral, FileEarmarkText, FileEarmarkTextFill, Markdown, MarkdownFill, Star, StarFill } from "react-bootstrap-icons";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import { useFavourite } from "components/useFavourite";
import { useLastViewed } from "components/useLastViewed";
import { getPost } from "app/Api";
import { Loader } from "components/Loader";

import './Post.scss';
import { useLocalStorage } from "components/useLocalStorage";
import Back from "components/Back";

const Post = () => {
    const [post, setPost] = useState(null);
    const [style, setStyle] = useLocalStorage('style', 'text');
    const params = useParams();
    const postId = params.postId
    const [lastViewed, addLastViewed] = useLastViewed();

    useEffect(() => {
        const _getPost = async () => {
            const post = await getPost(postId)
            if (post) {
                setPost(post);
                addLastViewed(postId);
            } else {
                setPost(false);
            }
        };

        _getPost();
    }, [postId]);

    return <>
        <Back/>
          
        {post && <div className="btn-group float-end">
            {/* {style === 'markdown' && <><button className="btn btn-primary active"><MarkdownFill/></button><button className="btn btn-outline-primary" onClick={() => setStyle('text')}><FileEarmarkText/></button></>} */}
            {/* {style === 'text' && <><button className="btn btn-outline-primary" onClick={() => setStyle('markdown')}><Markdown/></button><button className="btn btn-primary active"><FileEarmarkTextFill/></button></>} */}
        </div>}

        <h2 className="mt-0 text-center">{postId}{post && <FavouriteStar id={postId} className="py-0 px-2 mb-2"/>}</h2>
        <div className="clearfix mb-4"/>

        {post === null && <div className="text-center"><Loader/></div>}
        {post === false && <h2 className="mt-3">Not found <EmojiNeutral/></h2>}

        {post && style === 'markdown' && <ReactMarkdown remarkPlugins={[gfm]} children={post.replaceAll("\t", "&emsp;")} className="content"/>}
        {post && style === 'text' && <pre className="border border-primary bg-light p-3">{post.trim()}</pre>}
    </>;
};

const FavouriteStar = ({ id, className }) => {
    const [favourite, addFav, removeFav] = useFavourite();
    const isFavourite = favourite.find(i => i === id);
    return <button className={`btn btn-link ${className}`} onClick={() => isFavourite ? removeFav(id) : addFav(id)}>
        {isFavourite ? <StarFill/> : <Star/>}
    </button>;
}

export default Post;