import React, { useState } from "react";
import { CloudArrowUpFill } from "react-bootstrap-icons";

import { upload } from "app/Api";
import { ItemList } from "components/Items";
import { Loader } from "components/Loader";
import Back from "components/Back";

const Load = () => {
    const [uploadState, setUploadState] = useState(0);
    const [files, setFiles] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const onChange = e => setFiles(e.target.files);
    
    const onSubmit = async () => {
        setUploadState(1);
        setUploadedFiles([]);

        const data = new FormData();
        let i = 0;
        for (const file of files) {
            data.append('file-' + (i++), file);
        }
        
        const resp = await upload(data);
        
        if (resp) {
            setUploadState(2);
            setFiles(null);
            setUploadedFiles(resp.names);
        } else {
            setUploadState(3);
        }
    }

    const resetUploader = () => {
        setUploadState(0);
        setFiles(null);
        setUploadedFiles([]);
    }

    return <>
        <Back/>
        
        <h2 className="mt-0 text-center">Upload files</h2>
        <div className="clearfix mb-4" />

        {uploadState === 0 && <div className="input-group input-group-lg mt-3 mb-4">
            <input type="file" className="form-control" multiple onChange={onChange} />
            <button className="btn btn-primary" type="button" disabled={files === null} onClick={onSubmit}>Upload <CloudArrowUpFill/></button>
        </div>}

        {uploadState === 1 && <div className="text-center"><Loader/></div>}
        {uploadState === 2 && uploadedFiles.length > 0 && <>
            <button className="btn btn-outline-primary float-end" onClick={resetUploader}>Upload more</button>
            <ItemList items={uploadedFiles} header="Uploaded"/>
        </>}

        {uploadState === 3 && <div className="alert alert-danger" role="alert">
            An error occured. Try again.
        </div>}
    </>
};

export default Load;