import { Link } from "react-router-dom";

const ItemList = ({ items, limit, header }) => <>
    {header && <h4 className="mt-4">{header}:</h4>}
    <ul className="list-unstyled">
        {items.slice(0, limit).map(i => <li key={i} ><Item id={i}/></li>)}
    </ul>
</>

const Item = ({ id }) => <Link to={`/posts/${id}`} className="btn btn-link btn-lg ps-0">{id}</Link>

export { ItemList }