import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from '../pages/Home.js';
import Post from '../pages/Post.js';
import Load from '../pages/Load.js';

const Router = props => <Routes>
    <Route path="/">
        <Route index element={<Home />} />
        <Route path="load" element={<Load />} />
        <Route path="posts">
            <Route path=":postId" element={<Post />} />
        </Route>
    </Route>
</Routes>;

export default Router;