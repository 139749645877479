import React from "react";
import Router from './Router';

import './App.scss';

function App() {
    return (

        <div className="container-fluid">
            <div className="row g-0 mt-2">
                <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 pt-3">
                    <Router></Router>
                </div>
            </div>
        </div>

    );
}

export default App;
